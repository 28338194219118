<template>
    <div>
        <CModal
            :show.sync="showModal"
            :close-on-backdrop="false"
            :centered="true"
            title="Modal title 2"
            size="lg"
            color="dark"
        >
            <div>
                <label>Property Id</label>
                <CInput v-model="form.property_id" type="text" disabled />
            </div>
            <label class="required-field">Start Date</label>
            <CInput v-model="form.start_date" type="date" />
            <span v-if="$v.form.start_date.$error" class="text-danger"
                >Date is required</span
            >
            <div>
                <label class="required-field">Contract length</label>
            </div>
            <CInput
                v-model="form.contract_length"
                type="number"
                placeholder="Enter Contract length"
            />
            <span v-if="$v.form.contract_length.$error" class="text-danger"
                >Contract length is required</span
            >
            <div>
                <label class="required-field">Layout</label>
            </div>
            <CInput v-model="form.layout" placeholder="Enter Layout" />
            <span v-if="$v.form.layout.$error" class="text-danger"
                >Layout is required</span
            >
            <div>
                <label class="required-field">Capacity</label>
            </div>
            <CInput
                v-model="form.capacity"
                type="number"
                placeholder="Enter Capacity"
            />
            <span v-if="$v.form.capacity.$error" class="text-danger"
                >Capacity is required</span
            >

            <div class="row">
                <div class="col-6 d-flex flex-column flex-md-row">
                    <div class="col-6 d-flex flex-column p-0">
                        <label
                            class="c-switch c-switch-pill c-switch-primary mt-4 md-mt0"
                        >
                            <span>Signed</span>
                            <input
                                type="checkbox"
                                class="c-switch-input"
                                v-model="form.signed"
                            />
                            <span class="c-switch-slider"></span>
                        </label>
                        <label
                            class="c-switch c-switch-pill c-switch-primary mt-4"
                        >
                            <span>Approved</span>
                            <input
                                type="checkbox"
                                class="c-switch-input"
                                v-model="form.approved"
                            />
                            <span class="c-switch-slider"></span>
                        </label>
                        <label
                            class="c-switch c-switch-pill c-switch-primary mt-4"
                        >
                            <span>FirstRent</span>
                            <input
                                type="checkbox"
                                class="c-switch-input"
                                v-model="form.first_rent"
                            />
                            <span class="c-switch-slider"></span>
                        </label>
                    </div>
                    <div class="col-6 d-flex flex-column p-0">
                        <label
                            class="c-switch c-switch-pill c-switch-primary mt-4 md-mt0"
                        >
                            <span>LastRent</span>
                            <input
                                type="checkbox"
                                class="c-switch-input"
                                v-model="form.last_rent"
                            />
                            <span class="c-switch-slider"></span>
                        </label>
                        <label
                            class="c-switch c-switch-pill c-switch-primary mt-4"
                        >
                            <span>Add_on_cost</span>
                            <input
                                type="checkbox"
                                class="c-switch-input"
                                v-model="form.add_on_cost"
                            />
                            <span class="c-switch-slider"></span>
                        </label>
                    </div>
                </div>
                <div class="col-6 d-flex flex-column flex-md-row">
                    <div class="col-6 d-flex flex-column p-0">
                        <label
                            class="c-switch c-switch-pill c-switch-primary mt-4 md-mt0"
                        >
                            <span>Materials_ordered</span>
                            <input
                                type="checkbox"
                                class="c-switch-input"
                                v-model="form.materials_ordered"
                            />
                            <span class="c-switch-slider"></span>
                        </label>
                        <label
                            class="c-switch c-switch-pill c-switch-primary mt-4"
                        >
                            <span>Assembly_started</span>
                            <input
                                type="checkbox"
                                class="c-switch-input"
                                v-model="form.assembly_started"
                            />
                            <span class="c-switch-slider"></span>
                        </label>
                        <label
                            class="c-switch c-switch-pill c-switch-primary mt-4"
                        >
                            <span>Setup_completed</span>
                            <input
                                type="checkbox"
                                class="c-switch-input"
                                v-model="form.setup_completed"
                            />
                            <span class="c-switch-slider"></span>
                        </label>
                    </div>
                    <div class="col-6 d-flex flex-column p-0">
                        <label
                            class="c-switch c-switch-pill c-switch-primary mt-4 md-mt0"
                        >
                            <span>Send_via_fedex</span>
                            <input
                                type="checkbox"
                                class="c-switch-input"
                                v-model="form.send_via_fedex"
                            />
                            <span class="c-switch-slider"></span>
                        </label>
                        <label
                            class="c-switch c-switch-pill c-switch-primary mt-4"
                        >
                            <span>Arrived_in_mail</span>
                            <input
                                type="checkbox"
                                class="c-switch-input"
                                v-model="form.arrived_in_mail"
                            />
                            <span class="c-switch-slider"></span>
                        </label>
                    </div>
                </div>
            </div>

            <template #header>
                <h6 class="modal-title">Contracts</h6>
                <CButtonClose @click="onCloseModal()" class="text-white" />
            </template>
            <template #footer>
                <CButton @click.prevent="saveModal" color="success"
                    >Save</CButton
                >
                <CButton @click="onCloseModal()" color="danger">Cancel</CButton>
            </template>
        </CModal>
    </div>
</template>

<script>
import { postContract, getContract, putContract } from "@/api/contracts";
import { required } from "vuelidate/lib/validators";

export default {
    name: "contractEditForm",
    props: {
        id: {
            type: Number,
            default: 0,
        },
        modalShow: {
            type: Boolean,
            default: false,
        },
        onHideModal: {
            type: Function,
        },
    },
    created() {
        this.showModal = this.$props.modalShow;
        if (this.$props.id) {
            getContract(this.$props.id)
                .then((res) => {
                    this.form = res.data.data;
                    delete this.form.terms_and_condition_file;
                    delete this.form.additional_options;
                })
                .catch((error) => {
                    console.log(error, "error");
                });
        }
    },
    data: () => {
        return {
            showModal: false,
            form: {
                start_date: "",
                contract_length: "",
                layout: "",
                capacity: "",
                signed: "",
                approved: "",
                first_rent: "",
                last_rent: "",
                add_on_cost: "",
                materials_ordered: "",
                assembly_started: "",
                setup_completed: "",
                send_via_fedex: "",
                arrived_in_mail: "",
            },
            errors: null,
        };
    },
    methods: {
        changeModal() {
            if (!this.showModal) {
                this.onCloseModal();
            }
        },
        onCloseModal() {
            this.showModal = false;
            this.$props.onHideModal();
        },
        saveModal() {
            this.$v.form.$touch();
            if (this.$v.form.$error) {
                return;
            }
            console.log(this.form, "========");
            if (this.$props.id) {
                putContract(this.$props.id, this.form)
                    .then((res) => {
                        this.$emit(
                            "saveContract",
                            res.data.data,
                            this.$props.id
                        );
                        this.$notify({
                            group: "notify",
                            type: "success",
                            text: res.data.message,
                        });
                        this.onCloseModal();
                    })
                    .catch((error) => {
                        console.log(error, "error");
                    });
            } else {
                postContract(this.form)
                    .then((res) => {
                        console.log(res);
                        this.$emit("saveContract", res.data.data);
                        this.$notify({
                            group: "notify",
                            type: "success",
                            text: res.data.message,
                        });
                        this.onCloseModal();
                    })
                    .catch((error) => {
                        console.log(error, "error");
                    });
            }
        },
    },
    validations: {
        form: {
            start_date: {
                required,
            },
            contract_length: {
                required,
            },
            layout: {
                required,
            },
            capacity: {
                required,
            },
        },
    },
};
</script>
